<template>
    <div>
        <!--form add & update dashUser-->

        <ek-dialog
            btnText="مستخدم جديد"
            ref="dialog"
            :isEdit="!!dtoDetailsDashUser.id"
            :title="
                dtoDetailsDashUser.id
                    ? 'تعديل مستخدم لوحة'
                    : 'اضافة مستخدم لوحة'
            "
            @delete="deleteDashUser(dtoDetailsDashUser.id)"
            @ok="submitForm"
        >
        <template #filter >

            <!-- <div class="row d-flex justify-content-end"> -->
            <div class="col-md-8 flex justify-content-center p-2">
                <b-button
                    type="submit"
                    class="mr-1"
                    variant="primary"
                    @click="
                        getDashUser({ ...filterDto, search: search.search })
                    "
                    >فلترة</b-button
                >
                <b-button variant="secondary" @click="resetFilter"
                    >تهيئة</b-button
                >
                <ek-input-text
                    v-model="filterDto.search"
                    placeholder="ابحث عن اسم أو رقم محدد"
                ></ek-input-text>
            </div>
        <!-- </div> -->
        </template>
            <template #body>
                <validationObserver ref="dashUserForm">
                    <ek-input-text
                        v-model="dtoDetailsDashUser.userName"
                        name="اسم "
                        placeholder="ادخل اسم المستخدم"
                        label="اسم المستخدم"
                        :rules="[
                            { type: 'required', message: 'اسم المستخدم مطلوب' },
                        ]"
                    ></ek-input-text>

                    <ek-input-text
                        v-model="dtoDetailsDashUser.name"
                        name="nname "
                        placeholder="ادخل  الاسم الكامل"
                        label="الاسم الكامل "
                        :rules="[
                            {
                                type: 'required',
                                message: ' الاسم الكامل مطلوب',
                            },
                        ]"
                    ></ek-input-text>

                    <ek-input-text
                        v-model="dtoDetailsDashUser.email"
                        :rules="[
                            {
                                type: 'required',
                                message: 'البريد الإلكتروني مطلوب',
                            },
                            {
                                type: 'email',
                                message: 'يرجى ادخال بريد الكتروني صحيح',
                            },
                        ]"
                        placeholder="ادخل البريد الإلكتروني"
                        name=" email"
                        label="البريد الالكتروني"
                    ></ek-input-text>
                    <ek-input-text
                        v-model="dtoDetailsDashUser.password"
                        placeholder="********"
                        name="كلمة السر"
                        :rules="[
                            { type: 'required', message: 'كلمة المرور مطلوبة' },
                            {
                                type: 'min:8',
                                message:
                                    'كلمة المرور يجب ان تحوي 8 محارف على الاقل',
                            },
                        ]"
                    ></ek-input-text>
                    <ek-input-text
                        v-model="dtoDetailsDashUser.phoneNumber"
                        :rules="[
                            { type: 'required', message: 'رقم الموبايل مطلوب' },
                            { type: 'mobile', message: 'رقم الهاتف غير صالح' },
                        ]"
                        placeholder="ادخل رقم الموبايل"
                        name="رقم الهاتف"
                        label="رقم الموبايل"
                    ></ek-input-text>

                    <ek-input-select
                        :options="dashUserRole"
                        v-model="dtoDetailsDashUser.role"
                        name=" الصلاحيات"
                        label=" الصلاحيات"
                    ></ek-input-select>

                    <ek-input-select
                        v-if="dtoDetailsDashUser.role == 'DataEntry'"
                        :options="subjects.subjects"
                        v-model="dtoDetailsDashUser.subjectIds"
                        multiple
                        name=" المواد"
                        label=" المواد"
                    ></ek-input-select>

                    <ek-input-text
                        v-model="dtoDetailsDashUser.address"
                        name="العنوان "
                        placeholder="ادخل العنوان "
                        label="العنوان "
                        :rules="[
                            { type: 'required', message: 'العنوان  مطلوب' },
                        ]"
                    ></ek-input-text>
                </validationObserver>
            </template>
        </ek-dialog>
        <!-- <div class="row d-flex justify-content-end">
            <div class="col-md-8 flex justify-content-center p-2">
                <b-button
                    type="submit"
                    class="mr-1"
                    variant="primary"
                    @click="
                        getDashUser({ ...filterDto, search: search.search })
                    "
                    >فلترة</b-button
                >
                <b-button variant="secondary" @click="resetFilter"
                    >تهيئة</b-button
                >
                <ek-input-text
                    v-model="filterDto.search"
                    placeholder="ابحث عن اسم أو رقم محدد"
                ></ek-input-text>
            </div>
        </div> -->
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
    computed: {
        ...mapState({
            subjects: (state) => state.subjects.subjects,
            dashUserRole: (state) => state.dashboardUsers.dashUserRole,
            dtoDetailsDashUser: (state) =>
                state.dashboardUsers.dtoDetailsDashUser,
            isDialogOpen: (state) => state.adverts.isDialogOpen,
            search: (state) => state.dashboardUsers.filterDto,
            filterDto: (state) => state.dashboardUsers.filterDto,
        }),
    },
    methods: {
        ...mapActions([
            "addDashUser",
            "updateDashUser",
            "deleteDashUser",
            "getSubjects",
            "getDashUser",
        ]),
        resetFilter() {
            Object.assign(this.filterDto, {
                search: null,
            });
            this.getDashUser(this.filterDto);
        },
        //submit add user |update
        submitForm() {
            this.$refs.dashUserForm.validate().then((suc) => {
                if (suc) {
                    if (this.dtoDetailsDashUser.id) {
                        this.dtoDetailsDashUser.newPassword =
                            this.dtoDetailsDashUser.password;
                        this.updateDashUser(this.dtoDetailsDashUser);
                        this.$refs.dialog.close();
                    } else {
                        if (
                            this.dtoDetailsDashUser.role == "Admin" ||
                            this.dtoDetailsDashUser.role == "Accountant"
                        ) {
                            this.dtoDetailsDashUser.subjectIds = [];
                            this.addDashUser(this.dtoDetailsDashUser)
                                .then((data) => {
                                    console.log(data);
                                    this.$refs.dialog.close();
                                })
                                .catch(() => {});
                        } else {
                            this.addDashUser(this.dtoDetailsDashUser)
                                .then((data) => {
                                    console.log(data);
                                    this.$refs.dialog.close();
                                })
                                .catch(() => {});
                        }
                    }
                }
            });
        },
        resetForm() {
            this.$refs.dashUserForm.reset();
            this.$store.commit("SET_USER_DTO");
            this.$store.commit("IS_DIALOG_OPEN", false);
        },
        setSearch(query) {
            this.$store.commit("Set_Search_Dto", {
                keys: ["name", "userName", "email"],
                query,
            });
        },
    },
    watch: {
        isDialogOpen(nv) {
            if (nv) {
                this.$refs.dialog.open();
            }
        },
    },
    created() {
        this.getSubjects();
        this.getDashUser(this.filterDto);
    },
};
</script>

<style></style>
